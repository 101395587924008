import SeriesEditorView, { operations, operationsModes } from 'myassays-global/views/SeriesEditorView';
import ParamsTableView, {dataTypes, editModes} from 'myassays-global/views/ParamsTableView';
import { MyassaysComponent, Model, PropTypes, Utils } from 'myassays-global';

const DECIMAL_SEPARATOR_POINT = '.';
const DECIMAL_SEPARATOR_COMMA = ',';

const template = (id, state) => `\
<div class="analytes-table-root border rounded"></div>
${state.editConcs ? `\
<div class="controls">
<div class="series-editor-root show"></div>
<div class="units-editor ${state.showUnits ? 'show' : ''}">
    <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" name="open-units-editor" id="${id}open-units-editor">
        <label class="form-check-label" for="${id}open-units-editor">≡ Units</label>
    </div>
    <div class="editor-controls ${state.commonUnitsMode ? 'open' : ''}">
        <input name="units" type="text" value="${state.commonUnits}"/>
    </div>
</div>
</div>
<div class="group-table-root border rounded"></div>` : ''}
`

function csvToArray(value) {
    return (value.match(/("[^"]+"|[^ ,"][^,"]*)/g) || []).map(item => item.replace(/^ *"?(.*?)"? *$/, '$1'));
}

function getDefaultName(index) {
    return `Analyte ${index + 1}`;
}

class BlazorCompatibleEvent extends CustomEvent {
    constructor(type, options) {
        super(type, {...options, bubbles: true});
    }
}

export default class MyassaysParamsMultiplex extends MyassaysComponent {
    static get uniqueId() {
        if (this._uniqueIdCounter === undefined) {
            this._uniqueIdCounter = 1;
        } else {
            this._uniqueIdCounter++;
        }
        return `--myassays-params-multiplex-${this._uniqueIdCounter}-`;
    }

    static events = {
        DATA_CHANGE: 'datachange',
        ROW_SELECT_CALIBRATOR: 'rowselectcalibrator',
        ERROR: 'error',
    }

    static errorCodes = ParamsTableView.errorCodes;

    static get propTypes() {
        return {
            id: PropTypes.string,
            numAnalytes: PropTypes.number.min(1).required.observed
                .comment('No. of rows in left hand table'),
            analyteNames: PropTypes.string.default('').observed
                .comment('If not supplied, defaults to "Analyte 1"...'),
            editNames: PropTypes.bool.default(false)
                .comment('If true, analyte names are editable'),
            numGroups: PropTypes.number.min(0).default(0).observed
                .comment('No. of rows in right hand table'),
            decimalSeparator: PropTypes.string.lookup([DECIMAL_SEPARATOR_POINT, DECIMAL_SEPARATOR_COMMA]).default(DECIMAL_SEPARATOR_POINT),
            typeColour: PropTypes.string.default('white')
                .comment('Colour of the circle icon in the right hand table'),
            dataValues: PropTypes.json
                .comment('The data to populate the control'),
            editConcs: PropTypes.bool.default(true)
                .comment('If false, only the left hand table is rendered'),
            analyteNumCol: PropTypes.bool.default(false)
                .comment('If true, an extra column is added to the left hand table showing the row number'),
            groupNameBase: PropTypes.string.default('Standard')
                .comment('Group name used in the Calibrator table'),
            groupColName: PropTypes.string.default('Calibrator')
                .comment('Title for the group column of the Calibrator table'),
            valueColName: PropTypes.string.default('Conc.')
                .comment('Title for the value column of the Calibrator table'),
            showUnits: PropTypes.bool.default(true)
                .comment('If false, all columns and controls relating to units are removed'),
        }
    }

    constructor() {
        super();

        this._state = new Model({
            commonUnitsMode: false,
            commonUnits: '',
            selectedAnalyteIndex: 0,
            analyteDataSets: [],
            analyteNameArray: [],
            seriesMode: true,
            operation: '',
            operand: 0,
        });

        this._root = this;

        this._hasBeenRendered = false;
    }

    static get observedAttributes() {
        return PropTypes.getObserved(this.propTypes);
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (this._hasBeenRendered) {
            this._state.set(PropTypes.attributesToProps(this, attrName));
        }
    }

    connectedCallback() {
        this._state.set(PropTypes.attributesToProps(this));

        const { dataValues, analyteNames, editConcs, showUnits } = this._state.attributes;
        const analyteNameArray = csvToArray(analyteNames);
        if (editConcs) {
            let seriesMode, operation, operand, commonUnitsMode, commonUnits;
            let factor = dataValues.SeriesDataFactor || 0;
            if (factor) {
                seriesMode = true;
                operation = factor >= 1 ? operations.MULTIPLY : operations.DIVIDE;
                operand = factor >= 1 ? factor : 1/factor;
            } else {
                seriesMode = false;
                operation = operations.MULTIPLY;
                operand = 0;
            }
            if (showUnits) {
                const unitsUsed = dataValues.Analytes.map(item => item.Units).filter((item, i, array) => item !== array[i + 1]);
                commonUnitsMode = unitsUsed.length === 1;
                commonUnits = unitsUsed[0];
            } else {
                commonUnitsMode = false;
                commonUnits = '';
            }
            const analyteDataSets = dataValues.Analytes.map((item, i) => ({
                values: item.Values.split(',').map(value => Number(value)),
                units: item.Units,
            }));

            this._state.set({
                commonUnitsMode,
                commonUnits,
                analyteDataSets,
                analyteNameArray,
                operation,
                operand,
                seriesMode,
            });
        } else {
            const seriesMode = false;
            const commonUnitsMode = true;
            this._state.set({
                analyteNameArray,
                seriesMode,
                commonUnitsMode,
            });
        }

        this.render();
        this._hasBeenRendered = true;

        this._state.addEventListener('change', evt => {
            this.onStateChange();
        });

        this.requestDataChangeEvent();
    }

    render() {
        const state = this._state.attributes;
        this._root.innerHTML = template(MyassaysParamsMultiplex.uniqueId, state);
        state.editConcs && this.renderSeriesEditor();
        this.renderAnalytesTable();
        state.editConcs && this.renderValuesGroupTable();
        this.addControlListeners();
        this.onStateChange(true);
    }

    renderAnalytesTable() {
        const { columns, items } = this.analytesTableColumnsAndItems;

        this._analytesTableView = ParamsTableView.options
            .rootElement(this._root.querySelector('.analytes-table-root'))
            .columns(columns)
            .items(items)
            .selectable(true)
            .selectedRowIndex(this._state.get('selectedAnalyteIndex'))
            .createInstance();
    }

    get analytesTableColumnsAndItems() {
        const { commonUnitsMode, showUnits, analyteDataSets, analyteNameArray, seriesMode, editNames, analyteNumCol, valueColName } = this._state.attributes;
        const columns = [];
        if (analyteNumCol) {
            columns.push({name: 'number', heading: 'Analyte', dataType: dataTypes.LABEL, editMode: editModes.NONE})
        }
        columns.push({name: 'name', heading: analyteNumCol ? 'Name' : 'Analyte', dataType: editNames ? dataTypes.STRING : dataTypes.LABEL, editMode: editNames ? editModes.ALL : editModes.NONE});
        if (seriesMode) {
            columns.push({name: 'startConcentration', heading: `Start ${valueColName}`, dataType: dataTypes.NUMERIC, editMode: editModes.ALL});
        }
        if (!commonUnitsMode && showUnits) {
            columns.push({name: 'units', heading: 'Units', dataType: dataTypes.STRING, editMode: editModes.ALL});
        }
        const items = analyteDataSets.map((item, i) => {
            const analyteName = analyteNameArray[i];
            const analytesItem = {name: analyteName === undefined ? getDefaultName(i) : analyteName, startConcentration: item.values[0]};
            if (!commonUnitsMode) {
                analytesItem.units = item.units;
            }
            if (analyteNumCol) {
                analytesItem.number = i + 1;
            }
            return analytesItem;
        });
        return {
            columns,
            items,
        }
    }

    renderValuesGroupTable() {
        const { typeColour } = this._state.attributes;
        const { columns, items } = this.valuesGroupTableColumnsAndItems;
        this._valuesGroupTableView = ParamsTableView.options
            .rootElement(this._root.querySelector('.group-table-root'))
            .columns(columns)
            .typeColour(typeColour)
            .items(items)
            .selectable(true)
            .createInstance();
    }

    get valuesGroupTableColumnsAndItems() {
        const { selectedAnalyteIndex, seriesMode, analyteDataSets, analyteNameArray, groupNameBase, groupColName, valueColName, showUnits } = this._state.attributes;
        const { values, units } = analyteDataSets[selectedAnalyteIndex];
        let analyteName = analyteNameArray[selectedAnalyteIndex];
        if (analyteName === undefined) analyteName = getDefaultName(selectedAnalyteIndex);
        const columns = [
            {name: 'name', heading: groupColName, dataType: dataTypes.LABEL_WITH_TYPE},
            {name: 'data', heading: `${analyteName} ${valueColName}${units === '' || !showUnits ? '' : ` (${units})`}`, dataType: dataTypes.NUMERIC, editMode: seriesMode ? editModes.NONE : editModes.ALL},
        ];
        const items = values.map((value, i) => ({name: `${groupNameBase}${i + 1}`, data: value}));

        return {
            columns,
            items,
        }
    }

    renderSeriesEditor() {
        const { seriesMode, operation, operand } = this._state.attributes;
        this._seriesEditorView = new SeriesEditorView(this._root.querySelector('.series-editor-root'), seriesMode, operation, operationsModes.MULTIPLY_DIVIDE, 0, operand);
    }

    addControlListeners() {
        const { editConcs, showUnits } = this._state.attributes;
        const setState = attributes => this._state.set(attributes);
        const toggleState = attrName => {
            setState({[attrName]: !this._state.get(attrName)});
        }
        const addListener = (selector, type, listener) => {
            const element = this._root.querySelector(selector);
            if (element) {
                element.addEventListener(type, listener);
            }
        }

        this._analytesTableView.addEventListener(ParamsTableView.events.STATE_CHANGE, this.onAnalytesTableStateChange);
        this._analytesTableView.addEventListener(ParamsTableView.events.CELL_CHANGE, this.onAnalytesTableCellChange);
        this._analytesTableView.addEventListener(ParamsTableView.events.ERROR, this.onTableError);

        if (editConcs) {
            this._seriesEditorView.addEventListener(SeriesEditorView.events.STATE_CHANGE, this.onSeriesEditorStateChange);
            this._valuesGroupTableView.addEventListener(ParamsTableView.events.STATE_CHANGE, this.onValuesGroupTableStateChange);
            this._valuesGroupTableView.addEventListener(ParamsTableView.events.CELL_CHANGE, this.onValuesGroupTableCellChange);
            this._valuesGroupTableView.addEventListener(ParamsTableView.events.ERROR, this.onTableError);
            if (showUnits) {
                addListener('[name="units"]', 'change', this.onUnitsChange);
                addListener('[name="open-units-editor"]', 'change', evt => {
                    Utils.doAndResizeSmoothly(this._root.querySelector('.units-editor'), () => {
                        setState({commonUnitsMode: evt.target.checked});
                    });
                });
            }
        }
    }

    onStateChange(force) {
        const { editConcs, showUnits } = this._state.attributes;

        const hasChanged = (name, handler) => {
            force ? handler(this._state.get(name)) : this._state.hasChanged(name, handler);
        }
        const toggleClass = (selector, className, force) => {
            this._root.querySelector(selector).classList.toggle(className, force);
        }
        const updateDataSets = () => {
            delete this._dataSetsUpdatePendingId;
            const { seriesMode, analyteDataSets } = this._state.attributes;
            if (seriesMode) {
                const newAnalyteDataSets = analyteDataSets.map((dataSet, i) => ({
                    ...dataSet,
                    values: this.getAnalyteValues(i, dataSet.values[0]),
                }));
                this._state.set({analyteDataSets: newAnalyteDataSets});
            } else {
                // trigger a change event anyway
                this._state.set({analyteDataSets});
            }
        }

        hasChanged('selectedAnalyteIndex', newValue => {
            if (editConcs) {
                this._valuesGroupTableView.state = this.valuesGroupTableColumnsAndItems;
            }
        });

        hasChanged('analyteDataSets', newValue => {
            if (editConcs) {
                this._valuesGroupTableView.state = this.valuesGroupTableColumnsAndItems;
            }
            this.requestDataChangeEvent();
        });

        showUnits && hasChanged('commonUnitsMode', newValue => {
            if (editConcs) {
                toggleClass('.units-editor .editor-controls', 'open', newValue);
                this._root.querySelector('[name="open-units-editor"]').toggleAttribute('checked', newValue);
                if (newValue) {
                    const {analyteDataSets, commonUnits} = this._state.attributes;
                    this._state.set({
                        analyteDataSets: analyteDataSets.map(dataSet => ({
                            ...dataSet,
                            units: commonUnits
                        }))
                    });
                }
            }
            Utils.doAndResizeSmoothly(this._root.querySelector('.analytes-table-root'), () => {
                this._analytesTableView.state = this.analytesTableColumnsAndItems;
            });
        });

        hasChanged('seriesMode', newValue => {
            if (editConcs) {
                const { operand, analyteDataSets, selectedAnalyteIndex } = this._state.attributes;
                if ( newValue && operand === 0 ) {
                    const { values } = analyteDataSets[selectedAnalyteIndex];
                    if (values.length <= 1) {
                        this._seriesEditorView.setState({
                            operation: operations.MULTIPLY,
                            multiplyDivideOperand: 1,
                        });
                    } else {
                        const factor = values[1] / values[0];
                        if (factor >= 1) {
                            this._seriesEditorView.setState({
                                operation: operations.MULTIPLY,
                                multiplyDivideOperand: factor,
                            });
                        } else {
                            this._seriesEditorView.setState({
                                operation: operations.DIVIDE,
                                multiplyDivideOperand: 1/factor,
                            });
                        }
                    }
                }
                this._valuesGroupTableView.state = this.valuesGroupTableColumnsAndItems;
                updateDataSets();
            }
            this._analytesTableView.state = this.analytesTableColumnsAndItems;
        });

        hasChanged('operation', newValue => {
            if (editConcs) {
                updateDataSets();
            }
        });

        hasChanged('operand', () => {
            if (editConcs) {
                updateDataSets();
            }
        });

        hasChanged('analyteNameArray', newValue => {
            if (editConcs) {
                this._valuesGroupTableView.state = this.valuesGroupTableColumnsAndItems;
            }
            this._analytesTableView.state = this.analytesTableColumnsAndItems;
        });

        hasChanged('numGroups', (newValue, previousValue) => {
            if (editConcs) {
                const {analyteDataSets, seriesMode} = this._state.attributes;
                this._state.set({
                    analyteDataSets: analyteDataSets.map((dataSet, i) => ({
                        ...dataSet,
                        values: seriesMode ? this.getAnalyteValues(i, dataSet.values[0]) : (() => {
                            if (newValue < previousValue) {
                                return dataSet.values.slice(0, newValue);
                            } else {
                                const values = [...dataSet.values];
                                while (values.length < newValue) {
                                    values.push(0);
                                }
                                return values;
                            }
                        })(),
                    })),
                });
                this._valuesGroupTableView.state = this.valuesGroupTableColumnsAndItems;
            }
        });

        hasChanged('numAnalytes', (newValue, previousValue) => {
            const { analyteDataSets, selectedAnalyteIndex, commonUnitsMode, commonUnits, numGroups } = this._state.attributes;
            if (newValue < previousValue) {
                this._state.set({
                    analyteDataSets: analyteDataSets.slice(0, newValue),
                    selectedAnalyteIndex: Math.min(selectedAnalyteIndex, newValue - 1),
                });
            } else {
                while (analyteDataSets.length < newValue) {
                    analyteDataSets.push({
                        values: (new Array(numGroups)).fill(0),
                        units: commonUnitsMode ? commonUnits : '',
                    });
                }
                this._state.set({analyteDataSets});
            }
            this._analytesTableView.state = {
                ...this.analytesTableColumnsAndItems,
                selectedRowIndex: this._state.get('selectedAnalyteIndex'),
            };
        });

        hasChanged('analyteNames', newValue => {
            this._state.set({analyteNameArray: csvToArray(newValue)});
        });
    }

    onUnitsChange = evt => {
        const { value } = evt.target;
        this._state.set({
            commonUnits: value,
            analyteDataSets: this._state.get('analyteDataSets').map(dataSet => ({...dataSet, units: value})),
        });
    }

    onSeriesEditorStateChange = evt => {
        const model = evt.detail;

        model.hasChanged('open', newValue => {
            Utils.doAndResizeSmoothly(this._root.querySelector('.analytes-table-root'), () => {
                this._state.set({seriesMode: newValue});
                this._valuesGroupTableView.state = this.valuesGroupTableColumnsAndItems;
            });
        });

        model.hasChanged('operation', newValue => {
            this._state.set({operation: newValue});
        });

        model.hasChanged('multiplyDivideOperand', newValue => {
            this._state.set({operand: newValue});
        });
    }

    onAnalytesTableStateChange = evt => {
        const model = evt.detail;

        model.hasChanged('selectedRowIndex', newValue => {
            this._state.set({selectedAnalyteIndex: newValue});
        });
    }

    onAnalytesTableCellChange = evt => {
        const { rowIndex, columnName, newValue } = evt.detail;

        const { analyteDataSets, analyteNameArray } = this._state.attributes;
        switch (columnName) {
            case 'name':
                analyteNameArray[rowIndex] = newValue || getDefaultName(rowIndex);
                break;
            case 'startConcentration':
                analyteDataSets[rowIndex].values = this.getAnalyteValues(rowIndex, newValue);
                break;
            case 'units':
                analyteDataSets[rowIndex].units = newValue;
                break;
        }
        this._state.set({analyteDataSets, analyteNameArray});
    }

    onTableError = evt => {
        evt.preventDefault();
        const error = evt.detail;
        const event = new BlazorCompatibleEvent(MyassaysParamsMultiplex.events.ERROR, {
            detail: error,
            cancelable: true,
        });
        this.dispatchEvent(event) && alert(error.message);
    }

    getAnalyteValues(index, startConcentration) {
        let value = startConcentration;
        const values = [];
        const { operation, operand, numGroups } = this._state.attributes;
        for (let row = 0; row < numGroups; row++) {
            values.push(value);
            if (operation === operations.MULTIPLY) {
                value *= operand;
            } else {
                value /= operand;
            }
        }
        return values;
    }

    onValuesGroupTableStateChange = evt => {
        const model = evt.detail;

        model.hasChanged('selectedRowIndex', newValue => {
            this.dispatchRowSelectCalibratorEvent(newValue);
        });
    }

    onValuesGroupTableCellChange = evt => {
        const { rowIndex, columnName, newValue, previousValue } = evt.detail;
        const analyteDataSets = this._state.get('analyteDataSets');
        analyteDataSets[this._state.get('selectedAnalyteIndex')].values[rowIndex] = newValue;
        this._state.set({analyteDataSets});
    }

    requestDataChangeEvent() {
        if (!this._dataChangeEventPendingId) {
            this._dataChangeEventPendingId = setTimeout(() => {
                this.dispatchEvent(new BlazorCompatibleEvent(MyassaysParamsMultiplex.events.DATA_CHANGE, {
                    detail: JSON.stringify(this.dataValue),
                }));
                delete this._dataChangeEventPendingId;
            }, 1);
        }
    }

    dispatchRowSelectCalibratorEvent(rowIndex) {
        this.dispatchEvent(new BlazorCompatibleEvent(MyassaysParamsMultiplex.events.ROW_SELECT_CALIBRATOR, {
            detail: rowIndex,
        }));
    }

    get dataValue() {
        const { analyteDataSets, analyteNameArray, editNames, seriesMode, operation, operand, editConcs } = this._state.attributes;
        const dataValue = {};
        dataValue.Analytes = analyteDataSets.map((dataSet, i) => {
            const item = editConcs ? {
                Values: dataSet.values.join(','),
                Units: dataSet.units,
            } : {};
            if (editNames) {
                item.Name = analyteNameArray[i] || getDefaultName(i);
            }
            return item;
        });
        if (seriesMode) {
            dataValue.SeriesDataFactor = operation === operations.MULTIPLY ? operand : 1/operand;
        }
        return dataValue;
    }
}
