import MyassaysParamsMultiplex from './MyassaysParamsMultiplex';
import styles from './MyassaysParamsMultiplex.css?raw';

document.write(`
<style>
${styles}
</style>
`);

customElements.define('myassays-params-multiplex', MyassaysParamsMultiplex);

if (window.MyassaysParamsMultiplex !== undefined) {
    if (!(window.MyassaysParamsMultiplex instanceof Object)) {
        throw new Error('Could not create "window.MyassaysParamsMultiplex" namespace.');
    }
} else {
    window.MyassaysParamsMultiplex = {};
}
window.MyassaysParamsMultiplex = {
    ...window.MyassaysParamsMultiplex,
    getById: function (id) {
        const elem = document.getElementById(id);
        if (!elem) {
            throw `An instance of MyassaysParamsMultiplex with the id "${id}" could not be found.`;
        } else if (! elem instanceof MyassaysParamsMultiplex) {
            throw `The element with the id "${id}" is not a MyassaysParamsMultiplex.`;
        } else {
            return elem;
        }
    },
    getNameValue: function (id) {
        return this.getById(id).nameValue;
    },
    setNameValue: function (id, newValue) {
        this.getById(id).nameValue = newValue;
    },
    getDataValue: function (id) {
        return this.getById(id).dataValue;
    },
    setDataValue: function (id, newValue) {
        this.getById(id).dataValue = newValue;
    }
}
